export const allowedOrigin = (origin = location.origin) =>
  Boolean(
    origin &&
      (origin.endsWith('codefriends.net') ||
        origin.endsWith('localhost:8080') ||
        origin.endsWith('http://127.0.0.1:8080') ||
        origin.startsWith('http://127.0.0.1') ||
        origin.startsWith('https://ide.codefriends.net') ||
        origin.startsWith('https://www.codefriends.net') ||
        origin.startsWith('https://dev.codefriends.net') ||
        origin.startsWith('https://dev-codefriends-frontend.vercel.app') ||
        origin.startsWith('http://localhost')),
  );

export const whitelistTarget = (url: string) =>
  new RegExp(/^(?:(?:http|https):\/\/(?:\w+.)?)(githubusercontent.com|jsbin.com|)\/(?:.*)/g).test(
    url,
  );
